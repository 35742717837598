import React from "react";
import headerImgStyles from "./header_img.module.scss";
import BackgroundImage from "gatsby-background-image";

const HeaderImg = (props) =>
  props.data.data[props.model].cover ? (
    <BackgroundImage
      Tag="div"
      className={`columns is-mobile is-vcentered ${headerImgStyles.bgSplash}`}
      fluid={props.data.data[props.model].cover.localFile.childImageSharp.fluid}
    >
      <div className={`column is-12 has-text-centered `}>
        <h1 className={`title ${headerImgStyles.bgStyles}`}>
          {props.data.data[props.model].title}
        </h1>
      </div>
      <div className="column is-8 is-offset-2">
        <figure className="image is-3by1"></figure>
      </div>
    </BackgroundImage>
  ) : (
    <div
      className={`columns is-mobile is-vcentered ${headerImgStyles.bgSplash}`}
    >
      <div className="column is-12 has-text-centered ">
        <h1 className={`title ${headerImgStyles.bgStyles}`}>
          {props.data.data[props.model].title}
        </h1>
      </div>
      <div className="column is-8 is-offset-2">
        <figure className="image is-3by1"></figure>
      </div>
    </div>
  );

export default HeaderImg;
