import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import pageStyles from "./page.module.scss"
import Layout from "../components/layout"
import Head from "../components/head"
import HeaderImg from "../components/imgs/headerImg"

export const query = graphql`
  query($slug: String!) {
    sitePage(context: { slug: { eq: $slug } }) {
      context {
        htmlTable
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      title
      description
      date(formatString: "MMMM Do, YYYY")
      body {
        json
      }
      cover {
        localFile {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`

const Page = props => {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Link to={node.data.uri} className={``}>
            {children}
          </Link>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <h2 key={children} className="subtitle is-4">
            {children}
          </h2>
        )
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <h1 key={children} className="title">
            {children}
          </h1>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <h3 key={children} className="subtitle is-4">
            {children}
          </h3>
        )
      },
    },
  }

  useEffect(() => {
    let table = document.getElementById("tableID")
    if (table) {
      for (let cell of table.rows[0].cells) {
        cell.classList.add(`${pageStyles.tRow}`)
      }
    }
  })

  return (
    <Layout>
      <Head title={props.data.contentfulPage.title} />
      <section className="hero ">
        <div className="hero-body">
          <div className="container">
            <HeaderImg data={props} model="contentfulPage" />
            <section className="section">
              <div className="columns is-multiline is-mobile">
                <div className="column is-8 is-offset-2">
                  <div className="content is-medium">
                    {documentToReactComponents(
                      props.data.contentfulPage.body.json,
                      options
                    )}
                  </div>
                </div>
                <div className={`column is-12`}>
                  {props.data.sitePage.context.htmlTable ? (
                    <table
                      id={`tableID`}
                      className={`table is-striped is-bordered is-hoverable is-fullwidth`}
                      dangerouslySetInnerHTML={{
                        __html: props.data.sitePage.context.htmlTable,
                      }}
                    />
                  ) : undefined}
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Page
